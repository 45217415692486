<template>
  <td class="is-narrow has-background-white" style="white-space: pre-line;">{{ fieldPresenter({item: asset, header: header}) }}</td>
</template>
<script>

import _ from "lodash";

const MAXIMUM_FIELD_LENGTH = 25
export default {
  name: "TableDataField",
  props: {
    header: Object,
    asset: Object,
    checklistIds: Array
  },
  methods: {
    fieldPresenter({item, header}) {
      let text = 'default'

      if (header.field_type === 'asset_type') {
        const {type: assetType} = item
        if (!assetType) return '-'

        if (header.field_value.includes('.')) {
          return this.subFieldSelector(header.field_value, assetType)
        }

        return assetType[header.field_value] ?? '-'
      }

      if (header.field_value === 'last_inspection') {
        return this.getLastInspectionDate();
      }

      if (header.field_value === 'next_inspection') {
        return this.getNextInspectionDate();
      }

      if (header.field_type === 'customField') {
        const assetTypeCustomFieldForColumn = this.getAssetTypeCustomField(header.field_value)

        if (assetTypeCustomFieldForColumn) {
          let fieldId = assetTypeCustomFieldForColumn.field_id
          return this.asset.meta_data ? this.asset.meta_data[fieldId] : '-'
        }
        return '-'
      }

      if (header.field_value.includes('.')) {
        return this.subFieldSelector(header.field_value, item)
      }

      if (header.field_type === 'asset' && header.field_value === 'location') {
        return this.locationText(item.location)
      }

      if (header.field_type === 'asset' && this.isDateField(header)) {
        return this.formatDateField(item[header.field_value])
      }

      return item[header.field_value] ?? '-'
    },
    getAssetTypeCustomField(columnValue){
      const fields = this.asset.type.custom_fields_object;
      if(fields && fields.length > 0){
        return fields.find(field => field.label === columnValue)
      }
      return null
    },
    subFieldSelector(field, item) {
      let fields = field.split(".")
      return item[fields[0]][fields[1]] ?? '-'
    },
    getInspectionDate(type, order) {
      const dates = this.checklistIds.length > 0
          ? this.asset.checklist_inspection_dates?.filter(inspectionDate => this.checklistIds.includes(inspectionDate.checklist_id))
          : this.asset.checklist_inspection_dates

      return _.orderBy(dates, [type], [order])?.[0]?.[type] ?? '-'
    },

    getLastInspectionDate() {
      return this.getInspectionDate('last_inspection', 'desc')
    },

    getNextInspectionDate() {
      return this.getInspectionDate('next_inspection', 'asc')
    },
    locationText(location) {
      if (location) {
        return `Latitude: ${location.latitude}\nLongitude: ${location.longitude}`
      }
      return '-'
    },
    formatDateField(text) {
      if (!text) {
        return '-'
      }
      const date = new Date(text)

      if (isNaN(date.getTime())) {
        return '-'
      }
      return new Intl.DateTimeFormat(navigator.language, {dateStyle: 'medium', timeStyle: 'short'}).format(date)
    },
    isDateField(header) {
      return header.field_value === 'last_inspection' || header.field_value === 'next_inspection'
    },
  },
}
</script>