<template>
  <div class="mt-1 mb-1">
    <div class="is-flex is-justify-content-space-between">
      <div v-if="!isEditing" @click="enableEditing" class="is-flex align-items-center config-label">
        <icon class="has-text-grey mr-1" :icon="iconType"/>
        <span>{{ newLabel }}</span>
        <icon class="has-text-white edit-icon" icon="pen"/>
      </div>
      <input v-else
             type="text"
             v-model="item.label"
             @blur="updateColumnLabel"
             @keydown.enter="updateColumnLabel"
             ref="inputField">
      <switch-input
          :class="{'has-tooltip-left' : hasMaxFields}"
          :data-tooltip="tooltipText"
          classes="is-small pr-0"
          @input="updateSetting"
          :value="isSelected"
          :disabled="shouldDisable">
      </switch-input>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";

const MAXIMUM_ALLOWED_FIELDS = 15
export default {
  name: "FieldSelector",
  props: {
    item: Object
  },
  data: () => {
    return {
      isEditing: false,
      isEnabled: false,
    }
  },
  computed: {
    ...mapGetters('assetRegister', [
      'dataMap',
    ]),
    isSelected() {
      return this.dataMap.some(data => data.field_type === this.item.field_type && data.field_value === this.item.field_value)
    },
    newLabel(){
      return this.dataMap.find(data => data.field_type === this.item.field_type && data.field_value === this.item.field_value)?.label ?? this.item.label
    },
    shouldDisable() {
      return this.hasMaxFields && this.isSelected === false
    },
    hasMaxFields(){
      return this.dataMap.length >= MAXIMUM_ALLOWED_FIELDS
    },
    tooltipText(){
      return this.hasMaxFields && !this.isSelected ? 'Maximum 15 columns can be selected' : null
    },
    iconType(){
      const fieldMap = {
        code: 'barcode',
        risk: 'exclamation-circle',
        zone: 'crosshairs',
        'type.name': 'coins',
        is_defective: 'check-circle',
        location: 'map',
        barcode: 'qrcode',
        last_inspection: 'clock',
        next_inspection: 'calendar',
        'risk.level': 'exclamation-triangle',
      };

      return fieldMap[this.item.field_value] ?? 'map-marker';
    }
  },
  methods: {
    ...mapMutations('assetRegister', [
        'setDataMap'
    ]),
    enableEditing() {
      if(this.isSelected === false) {
        this.$toast.warning("You must select the field before editing its label.")
        return
      }
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.inputField.focus()
      })
    },
    updateSetting() {
      if(this.isSelected) {
        const filteredDataMap = this.dataMap.filter(data => !(data.field_type === this.item.field_type && data.field_value === this.item.field_value))
        this.setDataMap(filteredDataMap)
      } else {
        this.dataMap.push({field_type: this.item.field_type, field_value: this.item.field_value, column_index: this.getColumnIndex(), label: this.item.label})
      }
      this.renumberIndexes()
    },
    renumberIndexes() {
      const data = [...this.dataMap]
      data.forEach((item, index) => {item.column_index = index + 1})
      this.setDataMap(data)
    },
    getMaximumIndex() {
      return this.dataMap.reduce((maximum, item) => {
        return item.column_index > maximum ? item.column_index : maximum
      }, 0)
    },
    getColumnIndex() {
      const currentMaximum = this.getMaximumIndex()
      return currentMaximum + 1
    },
    updateColumnLabel() {
      const dataItem = this.dataMap.find(data => data.field_type === this.item.field_type && data.field_value === this.item.field_value)
      const filteredDataMap = this.dataMap.filter(data => !(data.field_type === this.item.field_type && data.field_value === this.item.field_value))
      this.setDataMap(filteredDataMap)
      this.dataMap.push({field_type: this.item.field_type, field_value: this.item.field_value, column_index: dataItem.column_index, label: this.item.label})
      this.isEditing = false
    },
  },
}
</script>
<style scoped>
.config-label{
  &:hover{
    span {
      color: #868787 !important;
      cursor: pointer;
    }
  }
  span{
    transition: color 0.2s ease-in;
  }
}
.edit-icon {
  font-size: 0.75rem;
  margin-left: 0.4rem;
}

</style>