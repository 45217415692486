<template>
  <loader v-bind="{ loading }">

    <div class="p-3 box">
      <div class="is-flex align-items-start justify-between has-text-centered">
        <h2 class="title is-4">
          {{ title }}
        </h2>
        <div class="has-text-right">
          <dropdown-wrapper position="bottom-end">
            <action-button
                slot="trigger"
                :working="exporting"
                class="is-primary rounded-sml ml-1">
              <icon icon="download"></icon>
              <span>Download Report</span>
            </action-button>
            <dropdown-item @click="exportPdf">{{ title }} (PDF)</dropdown-item>
            <dropdown-item @click="exportToExcel">Excel Workbook (XLSX)</dropdown-item>
          </dropdown-wrapper>
          <action-button @click="showFlyout" class="is-cool-blue rounded-sml ml-1" v-if="isAdmin">
            <icon icon="cog"/>
            <span>Configure Report</span>
          </action-button>
        </div>
      </div>
      <div class="table-container mt-2" style="border-right: 0.5px #e8e8e8 solid">
        <table class="table is-fullwidth is-bordered">
          <thead>
          <draggable v-model="headers" tag="tr">
            <th v-for="(header, index) in headers" :key="index" scope="col"
                class="register-table-header is-narrow has-background-white">
              <div class="is-flex align-items-center justify-between">
                {{ header.label }}
                <icon class="has-text-grey-lighter" icon="grip-vertical"/>
              </div>
            </th>
          </draggable>
          </thead>
          <tbody>
          <tr v-for="asset in tableData" :key="asset.id">
            <table-data-field
                v-for="(header, index) in headers"
                :key="index"
                :header="header"
                :asset="asset"
                :checklist-ids="checklistIds"/>
          </tr>
          </tbody>
        </table>
        <div v-if="moreAssetsAvailable">
          <div class="load-more-box has-text-grey is-flex-column align-items-center justify-between" style="cursor: default;">
            <div class="has-text-grey has-text-centered p-05">
              Showing {{ assetsCount }} of {{ calculatedTotalAssets }}
            </div>
            <action-button
                :disabled="loadingMoreAssets"
                @click="loadMoreRegisterAssets"
                class="rounded-sml hover-bold is-primary is-inverted mt-1"
                :left-icon="loadingMoreAssets ? 'circle-notch' : 'plus-circle'">
              Load More {{ Naming.Assets }}
            </action-button>
          </div>
        </div>
      </div>
      <p class="has-text-centered has-text-grey" v-if="!assetsCount">No {{ Naming.Assets }} Found.</p>
    </div>
    <flyout ref="configuration" style="z-index: 2147483648">
      <div class="is-flex is-flex-column justify-between" style="height: 100%; flex-grow: 1;">
        <div class="pl-1 pr-1 mb-2">
          <div @click="showingFilters = !showingFilters" class="is-flex mb-1 mt-1 justify-between align-items-center section-header">
            <div class="is-flex align-items-center">
              <filter-icon class="mr-1"/>
              <h4>{{ Naming.Asset }} Filters</h4>
            </div>
            <rotating-chevron :rotate="showingFilters" />
          </div>
          <div class="pl-1 pr-1 mb-2" v-if="showingFilters">
            <p class="small-label" >Filter {{ Naming.Assets }} by {{ Naming.AssetType }}</p>
            <data-selector
                multiple
                searchable
                delete-buttons
                left-icon="coins"
                tag-class="is-info has-text-weight-bold"
                :value="selectedAssetTypes"
                :items="assetTypeList"
                label-key="name"
                value-key="id"
                @input="updateAssetTypes">
            </data-selector>
            <p class="small-label mt-2">Filter {{ Naming.Inspection }} dates by {{ Naming.Checklists }}</p>
            <data-selector
                :items="checklistList"
                left-icon="clipboard-list"
                tag-class="is-info has-text-weight-bold"
                searchable
                multiple
                label-key="name"
                value-key="id"
                :value="selectedChecklists"
                @input="updateChecklists">
            </data-selector>
            <hr>
          </div>
          <div @click="showingAssetFields = !showingAssetFields" class="is-flex mb-1 mt-1 justify-between align-items-center section-header">
            <div class="is-flex align-items-center">
              <icon class="has-text-grey mr-1" icon="qrcode"/>
              <h4>{{ Naming.Asset }} Fields</h4>
            </div>
            <rotating-chevron :rotate="showingAssetFields" />
          </div>
          <div class="pl-1" v-if="showingAssetFields">
            <field-selector v-for="item in assetFields" :key="item.field_value" :item="item"/>
            <field-selector v-for="item in checklistFields" :key="item.field_value" :item="item" class="mt-1"/>
            <field-selector v-for="item in assetTypeFields" :key="item.field_value" :item="item"/>
            <hr>
          </div>
          <div v-if="customAssetFields.length > 0">
            <div @click="showingCustomFields = !showingCustomFields" class="is-flex mb-1 mt-1 justify-between align-items-center section-header">
              <div class="is-flex align-items-center">
                <braces-icon class="mr-1" />
                <h4>{{ Naming.AssetType }} {{ Naming.CustomFields }}</h4>
              </div>
              <rotating-chevron :rotate="showingCustomFields" />
            </div>
            <div class="pl-1" v-if="showingCustomFields">
              <field-selector v-for="item in customAssetFields" :key="item.field_value" :item="item"/>
              <hr>
            </div>
          </div>
          <div @click="showingReportDetails = !showingReportDetails" class="is-flex mb-1 mt-1 justify-between align-items-center section-header">
            <div class="is-flex align-items-center">
              <file-icon class="mr-1"/>
              <h4>Report Options</h4>
            </div>
            <rotating-chevron :rotate="showingReportDetails" />
          </div>
          <div class="" v-if="showingReportDetails">
            <div @click="editingReportTitle = !editingReportTitle" class="is-flex mb-1 mt-1 is-justify-content-space-between report-section-header">
              <div class="is-flex align-items-center">
                <file-post-icon class="mr-1"/>
                <p>Report Title</p>
              </div>
              <rotating-chevron :rotate="editingReportTitle" />
            </div>
            <text-input
                class="ml-1 mr-1 mb-2"
                v-if="editingReportTitle"
                :error="validateInput(title, 255, 'Title')"
                :value="title"
                :hint="`The title of the report.`"
                @input="setTitle">
            </text-input>
            <div @click="editingPreamble = !editingPreamble" class="is-flex mb-1 is-justify-content-space-between report-section-header">
              <div class="is-flex align-items-center">
                <file-arrow-up-icon class="mr-1"/>
                <p>Report Preamble</p>
              </div>
              <rotating-chevron :rotate="editingPreamble" />
            </div>
            <text-area
                v-if="editingPreamble"
                rows="5"
                class="ml-1 mr-1 mb-2"
                placeholder="Edit report preamble"
                :value="preamble"
                :hint="`${title} Preamble`"
                @input="setPreamble">
            </text-area>
            <div @click="editingPostamble = !editingPostamble" class="is-flex mb-1 is-justify-content-space-between report-section-header">
              <div class="is-flex align-items-center">
                <file-arrow-down-icon class="mr-1"/>
                <p>Report Postamble</p>
              </div>
              <rotating-chevron :rotate="editingPostamble" />
            </div>
            <text-area
                v-if="editingPostamble"
                rows="5"
                class="ml-1 mr-1 mb-2"
                placeholder="Edit report postamble"
                :value="postamble"
                @input="setPostamble">
            </text-area>
            <div @click="editingJob = !editingJob" class="is-flex mb-1 mt-1 is-justify-content-space-between report-section-header">
              <div class="is-flex align-items-center">
                <icon class="has-text-grey mr-1" icon="hashtag"/>
                <p>Job Details</p>
              </div>
              <rotating-chevron :rotate="editingJob"/>
            </div>
            <data-selector
              v-if="editingJob"
              class="ml-1 mr-1 mb-1 mb-2"
              searchable
              @input="setJobId"
              :value="jobId"
              value-key="value"
              label-key="label"
              left-icon="circle-notch"
              prompt-label="Select a Job"
              :description="`The selected ${Naming.Job}'s number and ${Naming.Technician} details will be shown in the report`"
              :items="formattedJobList">
            Show details for a specific {{ Naming.Job }}
            <action-button
                class="is-primary"
                v-if="jobId"
                slot="right"
                @click="clearJobId">
              <icon icon="times"/>
            </action-button>
          </data-selector>
            <hr>
          </div>
        </div>
        <div class="pl-1 pr-1 mt-2 pb-2">
          <action-button @click="saveAndCloseFlyout" class="is-success is-fullwidth has-text-weight-semibold rounded-sml" v-if="isAdmin"
                         :working="saving">
            <icon icon="check-circle"/>
            <span>Save Configuration</span>
          </action-button>
      </div>
      </div>
    </flyout>

  </loader>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import TableDataField from "@/views/site/manager/asset-register/TableDataField.vue";
import FieldSelector from "@/views/site/manager/asset-register/FieldSelector.vue";
import {common as backend} from "@/api";
import download from "downloadjs";
import textLengthValidation from "@/utils/validationUtils";
import moment from "moment/moment";

export default {

  components: {
    FieldSelector,
    TableDataField
  },

  data: () => ({
    loading: true,
    loadingMoreAssets: false,
    exporting: false,
    saving: false,
    search: '',
    editingReportTitle: false,
    editingPreamble: false,
    editingPostamble: false,
    editingJob: false,
    showingFilters: false,
    showingAssetFields: false,
    showingCustomFields: false,
    showingReportDetails: false,
  }),

  async created() {
    Promise.all([
      this.fetchCompanyAssetRegisterConfiguration(),
      this.loadAssetTypeList(),
      this.loadChecklistList(),
    ])
    .then(() => this.fetchAssetRegisterAssets())
    .finally(() => this.loading = false)
    await this.$store.dispatch('assetRegister/loadJobs', this.site.id)
  },

  methods: {
    ...mapMutations('assetRegister', [
      'setCompanyAssetRegisterConfiguration',
      'setCompanyId',
      'setTitle',
      'setPreamble',
      'setPostamble',
      'setAssetTypes',
      'refreshCustomFields',
      'setChecklistIds',
      'clearChecklistIds',
      'setDataMap',
      'setJobId',
      'clearJobId',
    ]),
    ...mapActions('assetType', ['loadAssetTypeList']),
    ...mapActions('checklist', ['loadChecklistList']),
    saveAndCloseFlyout(){
      this.$refs.configuration.toggle()
      this.save()
    },
    async save() {
      this.saving = true
      this.sortDataMap()
      await this.$store.dispatch('assetRegister/store')
        .then(() => {
          this.$toast.success(`${this.title} saved.`)
        })
        .catch((error) => {
          this.$toast.warning(`${this.title} did not save. Please try again or contact support.`)
        })
        .finally(() => {
          this.saving = false
        })
    },
    sortDataMap(){
      const data = [...this.dataMap]
      data.forEach((item, index) => {item.column_index = index + 1})
      this.setDataMap(data)
      this.dataMap.sort((a, b) => a.column_index - b.column_index);
    },
    async downloadReport(formatType) {
      this.exporting = true
      const nullableJobId = this.jobId ? `/${this.jobId}` : '';
      await backend.downloadFile(`/api/sites/${this.$route.params.site}/asset-register/${formatType}${nullableJobId}`, ({data}) => {
        if (formatType === 'pdf') {
          return download(data, `${this.fileName}.pdf`, 'application/pdf')
        }
        return download(data, `${this.fileName}.xlsx`, 'application/vnd.ms-excel')
      }, error => {
        this.$whoops();
      }).finally(() => this.exporting = false)
    },
    async saveFilters(){
      await this.$store.dispatch('assetRegister/storeFilters', this.getPayload())
    },
    async exportPdf() {
      await this.save()
      await this.downloadReport('pdf')
    },
    async exportToExcel() {
      await this.save()
      await this.downloadReport('xlsx')
    },
    async updateAssetTypes(values) {
      const assetTypeIds = values.map(assetType => assetType.id)
      this.setAssetTypes(assetTypeIds)
      this.refreshCustomFields(this.customAssetFields)
      await this.saveFilters()
      this.fetchAssetRegisterAssets()
    },
    async updateChecklists(values) {
      const checklistIds = values.map(checklist => checklist.id)
      this.setChecklistIds(checklistIds)
    },
    showFlyout() {
      this.$refs.configuration.toggle()
    },
    fetchAssetRegisterAssets() {
      this.$store.dispatch('asset/loadAssetRegisterAssets', this.getPayload())
    },
    getPayload() {
      return {
        site: this.$route.params.site,
        assetTypes: this.assetTypes,
        checklist: this.checklist
      }
    },
    async fetchCompanyAssetRegisterConfiguration() {
      await this.$store.dispatch('assetRegister/fetchCompanyAssetRegisterConfiguration')
          .then((response) => {
            this.setCompanyAssetRegisterConfiguration(response)
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              this.setCompanyId(this.company?.id)
            }
          })
          .finally(() => this.loading = false);
    },
    async loadMoreRegisterAssets() {
      this.loadingMoreAssets = true
      backend.loadPath(
          {
            path: this.assetRegisterAssets.links.next,
            options: this.getPayload()
          },
          ({data}) => {
            this.assetRegisterAssets.links.next = data.links.next
            this.assetRegisterAssets.meta = data.meta
            data.data.forEach(asset => this.assetRegisterAssets.data.push(asset))
          },
          error => {
            this.$whoops()
          })
          .finally(() => {
            this.loadingMoreAssets = false
          })
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('asset', [
      'assetRegisterAssets',
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('company', [
      'company', 'getCompanyAssetRegisterConfiguration'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('assetRegister', [
      'companyId',
      'title',
      'preamble',
      'postamble',
      'assetTypes',
      'checklistIds',
      'dataMap',
      'jobList',
      'jobId'
    ]),
    assetsCount(){
      return this.assetRegisterAssets.data?.length ?? null
    },
    moreAssetsAvailable() {
      return this.assetRegisterAssets.links?.next?.length > 0
    },
    calculatedTotalAssets() {
      if (this.moreAssetsAvailable) {
        const toBeLoaded = this.assetRegisterAssets?.meta.total - this.assetRegisterAssets?.meta.to
        return this.assetsCount + toBeLoaded
      }
      return this.assetsCount
    },
    fileName() {
      let date = moment().format('D MMM YYYY HH:mm')
      return `${this.title} - Site #${this.site.id} - ${date}`
    },
    selectedAssetTypes() {
      if (this.assetTypes.length > 0) {
        return this.assetTypeList.filter(types => this.assetTypes.includes(types.id))
      }
      return []
    },
    selectedChecklists() {
      if (this.checklistIds.length > 0) {
        return this.checklistList.filter(checklist => this.checklistIds.includes(checklist.id))
      }
      return []
    },
    headers: {
      get: function () {
        return this.dataMap ?? []
      },
      set: function (data) {
        this.setDataMap(data)
      },
    },
    tableData() {
      return this.assetRegisterAssets?.data
    },
    assetFields() {
      return [
        {field_type: 'asset', field_value: 'code', label: 'Code'},
        {field_type: 'asset', field_value: 'risk', label: 'Asset Risk Level'},
        {field_type: 'asset', field_value: 'zone', label: 'Zone'},
        {field_type: 'asset', field_value: 'type.name', label: 'Asset Type'},
        {field_type: 'asset', field_value: 'is_defective', label: 'Status'},
        {field_type: 'asset', field_value: 'location', label: 'Location'},
        {field_type: 'asset', field_value: 'barcode', label: 'Barcode'},
      ]
    },
    checklistFields() {
      return [
        {field_type: 'asset', field_value: 'last_inspection', label: 'Last Inspection'},
        {field_type: 'asset', field_value: 'next_inspection', label: 'Next Inspection'},
      ]
    },
    assetTypeFields() {
      return [
        {field_type: 'asset_type', field_value: 'risk.level', label: 'Asset Type Risk'},
      ]
    },
    customAssetFields() {
      if (this.assetTypes.length === 0) return [];

      const allFields = this.assetTypeList
          .filter(assetType => this.assetTypes.includes(assetType?.id))
          .flatMap(assetType => {
            const customFields = assetType.custom_fields ? JSON.parse(assetType.custom_fields) : [];
            return customFields.map(customField => customField['label']);
          });

      const uniqueFields = [...new Set(allFields)];

      return uniqueFields.map(fieldLabel => ({
        field_type: 'customField',
        field_value: fieldLabel,
        label: fieldLabel,
      }));
    },
    formattedJobList() {
      return this.jobList?.map(job => ({ label: `#${job.jobNumber} - ${job.technician} - ${job.completedAt}`, value: job.id }))
    },
  },

}
</script>
