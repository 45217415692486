<template>
  <div>
    <div v-if="loaded">
      <div class="box columns">
        <div class="column">
          <h2 class="site-dash-heading">
            {{ site.name }}
            <br/>
            <span class="sub-heading">
              <router-link
                :to="{
                  name: 'client-overview',
                  params: { client: site.client_id }
                }">
                  {{ site.client.legal_name }}
              </router-link>
              <span class="has-text-grey">, created {{ site.created_at | date('L') }}</span>
            </span>
          </h2>
<!--Site Stats-->
          <site-stats :site="site"/>
        </div>
      </div>
      <div class="box mtb-1 columns">
        <div class="column">
          <div class="level-left is-flex w-full">
            <div class="level-item">
              <div>
                <p class="has-text-weight-medium is-size-4">Recent {{ Naming.Inspections }}</p>
              </div>
            </div>
          </div>
          <InspectionLocationsMap></InspectionLocationsMap>
        </div>
      </div>
<!--Inspections Graph-->
          <inspections-graph />
<!--Custom Fields-->
      <div class="box mt-2 mb-1 columns is-multiline">
        <div class="column">
            <h3 class="is-size-4">{{ Naming.CustomFields }}</h3>
          <p class="has-text-grey mb-1">A list of all the {{ Naming.CustomFields.toLowerCase() }} associated with this {{ Naming.Site.toLowerCase() }}</p>
          <table class="table is-fullwidth" v-if="site.custom_fields.length > 0">
            <thead>
            <tr>
              <th>Label</th>
              <th>Value</th>
              <th>Placeholder</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="field in site.custom_fields" :key="field.id">
                <td class="has-text-weight-medium">{{field.label}}</td>
                <td>{{field.value ? field.value : '-'}}</td>
                <td>{{field.placeholder ? field.placeholder : '-'}}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="mb-1">
            <notification class="is-cool-blue has-text-centered p-2 is-marginless">
              <p class="has-text-weight-bold">No {{ Naming.CustomFields }} Created</p>
              <p>You haven’t created any {{ Naming.CustomFields.toLowerCase() }} yet.</p>
            </notification>
          </div>
            <router-link
                v-if="$root.hasAbility('update-site-custom-field-values', 'App\\Models\\CustomField')"
                class="button is-info is-rounded"
                :to="{
                name: 'site-custom-fields',
                params: this.$route.params
              }"
            >
              <icon icon="clipboard-list"/>
              <span>{{ Naming.CustomFields }}</span>
            </router-link>
        </div>
      </div>
<!--Recently Defective Assets-->
      <div class="box mtb-1 columns">
        <div class="column">
          <div class="is-flex flex-wrap justify-between">
            <div class="level-left is-flex w-full justify-center">
              <div class="level-item">
                <div>
                  <p class="has-text-weight-medium is-size-4">Recently Defective {{ Naming.Assets }}</p>
                  <p class="has-text-weight-light"
                      v-if="defectiveHighRiskAssets.length"
                  >
                    Below are the {{ Naming.Assets.toLowerCase() }} of high
                    and critical classification recently marked as defective.
                  </p>
                </div>
              </div>
            </div>
            <div class="level-right is-flex w-full justify-center">
              <div class="buttons">
                <div v-if="defectiveHighRiskAssets.length">
                  <action-button
                      class="is-hidden-touch is-light"
                      :working="downloadingDefectiveReport"
                      @click="downloadDefectiveReport"
                      left-icon="file-pdf"
                  >
                    Defective {{ Naming.Asset }}
                    Report
                  </action-button>
                </div>

                <div class="is-flex align-items-center mx-4">
                  <dropdown-wrapper position="bottom-end" class="is-hidden-touch is-light mx-2">
                    <action-button
                        left-icon="download"
                        slot="trigger"
                        :working="exporting"
                        class="is-light">
                      Report By Date
                    </action-button>
                    <date-picker
                      class="ml-1 mr-1"
                      placeholder="Select Date Range"
                      style="width: 300px; margin-top: 4px;"
                      mode="range"
                      :value="inspectionDateRange"
                      @input="setInspectionDateRange"
                    ></date-picker>
                    <dropdown-item
                        :working="downloadingInspectionReport"
                        @click="downloadInspectionReport('pdf')">
                      Portable Document Format
                      (PDF)
                    </dropdown-item>
                    <dropdown-item
                        :working="downloadingInspectionReport"
                        @click="downloadInspectionReport('xlsx')"
                    >Excel Workbook (XLSX)</dropdown-item>
                  </dropdown-wrapper>
                </div>

                <action-button
                  class="is-hidden-touch is-light"
                  :working="downloadingStandardReport"
                  @click="downloadStandardReport"
                  left-icon="download"
                >Full Report</action-button>
              </div>
            </div>
          </div>

          <table
              class="table is-hoverable is-fullwidth"
              v-if="defectiveHighRiskAssets.length"
          >
            <thead>
            <tr>
              <th>Code</th>
              <th>{{ Naming.Zone }}</th>
              <th class="has-text-right is-hidden-mobile is-narrow">Risk Level</th>
              <th class="has-text-right is-hidden-mobile is-narrow">Date of Failure</th>
              <th class="has-text-right is-hidden-mobile is-narrow">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="asset in defectiveHighRiskAssets" :key="asset.id">
              <td>
                <span class="lh-1">
                  <router-link
                      class="has-text-weight-bold has-text-link"
                      :to="{
                    name: 'asset-inspections',
                    params: {
                      asset: asset.id,
                      site: asset.site_id
                    }
                  }"
                  >{{ asset.code }}</router-link>
                  <br/>
                  <small class="lh-1">{{ asset.type.name }}</small>
                </span>
              </td>
              <td>
                <strong>{{ asset.zone.name }}</strong>
                <br />
                {{ asset.zone.type.description }}
              </td>
              <td
                  class="has-text-right is-hidden-mobile has-text-danger"
                  :class="{ 'has-text-weight-bold': asset.risk.level === 'Critical' }"
              >{{ asset.risk.level }}</td>
              <td class="has-text-right is-hidden-mobile">
                <span
                    v-if="asset.last_failed_inspection"
                >{{ asset.last_failed_inspection.failed_at | date('L') }}</span>
                <!-- <span v-else>--</span> -->
              </td>
              <td class="is-narrow">
                <current-status :status="asset.current_status" />
              </td>
            </tr>
            </tbody>
          </table>
          <div class="notification p-1" v-else>There are no {{ Naming.Assets.toLowerCase() }} recently marked as defective.</div>
        </div>
      </div>
    </div>
    <loader v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { site as backend } from "@/api"
import LatestJob from "@/partials/LatestJob.vue"
import download from "downloadjs"
import HealthBars from "../partials/HealthBars"
import InspectionsGraph from "../partials/InspectionsGraph"
import SiteStats from '../partials/SiteStats'
import InspectionLocationsMap from "@/views/site/partials/RecentInspectionLocations.vue";

export default {
  components: {
    InspectionLocationsMap,
    LatestJob,
    HealthBars,
    InspectionsGraph,
    SiteStats
  },

  data: () => ({
    chartOptions: {
      cutoutPercentage: 85,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
    inspectionDateRange: "",
    loaded: false,
    exporting: false,
    downloadingInspectionReport: false,
    downloadingStandardReport: false,
    downloadingDefectiveReport: false,
  }),

  async created() {
    Promise.all([
      await this.$store.dispatch("site/loadSiteDataForOverview", this.$route.params.site),
      this.loaded = true
    ]).then(() => {
      this.$store.dispatch("site/loadDefectiveHighRiskAssets", this.$route.params.site)
    });
  },

  mounted() {
    this.inspectionDateRange = moment().startOf("month").format("Y-MM-DD") + " to " + moment().format("Y-MM-DD")
  },

  methods: {
    setInspectionDateRange(value) {
      this.inspectionDateRange = value
    },
    downloadInspectionReport(type = null) {
      this.downloadingInspectionReport = true
      if (type === "pdf") {
        backend.downloadInspectionReportByDateRange({
            siteId: this.site.id,
            date_range: this.inspectionDateRange,
          },
          ({ data }) => {
            this.downloadingInspectionReport = false
            download(
              data,
              `#${this.site.id}-${this.site.name}-inspections-${this.inspectionDateRange}.pdf`,
              "application/pdf"
            );
          }, (error) => {
            this.$whoops()
            this.downloadingInspectionReport = false
          }
        );
      } else if (type === "xlsx") {
        backend.downloadInspectionReportByDateRangeAsExcel({
            siteId: this.site.id,
            date_range: this.inspectionDateRange,
          }, ({ data }) => {
            this.downloadingInspectionReport = false;
            download(
              data,
              `#${this.site.id}-${this.site.name}-inspections-${this.inspectionDateRange}.xlsx`,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
          }, (error) => {
            this.$whoops();
            this.downloadingInspectionReport = false
          }
        );
      }
    },
    downloadStandardReport() {
      this.downloadingStandardReport = true;
      backend.downloadStandardReport(
        this.site.id,
        ({ data }) => {
          this.downloadingStandardReport = false;
          download(
            data,
            `#${this.site.id}-${this.site.name}.pdf`,
            "application/pdf"
          );
        },
        (error) => {
          this.$whoops();
          this.downloadingStandardReport = false;
        }
      );
    },
    downloadDefectiveReport() {
      this.downloadingDefectiveReport = true;
      backend.downloadDefectiveReport(
        this.site.id,
        ({ data }) => {
          this.downloadingDefectiveReport = false;
          download(
            data,
            `#${this.site.id}-${this.site.name}-Defective.pdf`,
            "application/pdf"
          );
        },
        (error) => {
          this.$whoops();
          this.downloadingDefectiveReport = false;
        }
      );
    },
  },

  computed: {
    ...mapGetters("site", ["site", "overview", "defectiveHighRiskAssets"]),
  },
};
</script>
